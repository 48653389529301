<template>
  <v-row class="text-center">
    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
    <v-spacer></v-spacer>
    <v-col align="center" class="col-12 col-sm-3">
      <v-card class="pa-5">
        <v-form
          v-if="!isPasswordChanged"
          @submit.prevent
          ref="form"
          v-model="valid"
        >
          <h2>Change Your Password</h2>
          <v-text-field
            v-model="password1"
            :rules="passwordRules"
            label="New Password"
            type='password'
            required
            @keydown.enter="submit"
          ></v-text-field>
          <v-text-field
            v-model="password2"
            :rules="passwordRules"
            label="Confirm Password"
            type='password'
            required
            @keydown.enter="submit"
          ></v-text-field>
          <v-btn
            :loading="loading"
            @click="submit"
            color="primaryButton"
            class="mt-3 white--text"
          >
            Submit
          </v-btn>
          <div class="error--text mt-3">{{ error }}</div>
        </v-form>
        <div v-else>Password change successful.</div>
      </v-card>
    </v-col>
    <v-spacer></v-spacer>
    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ChangePassword',

    data () {
      return {
        error: '',
        isPasswordChanged: false,
        loading: false,
        password1: '',
        password2: '',
        valid: false
      }
    },

    computed: {
      passwordRules() {
        const { password1, password2 } = this
        return [
          v => !!v || 'Field is required.',
          v => v.length >= 8 || 'Passwords must contain at least 8 characters.',
          password1 == password2 || 'Passwords must match.'
        ]
      }
    },

    methods: {
      submit () {
        this.error = ''
        this.isPasswordChanged = false
        if (this.valid && !this.loading) {
          this.loading = true
          this.$store.dispatch('updatePassword', { newPassword: this.password1 })
          .then(() => {
            this.loading = false
            this.isPasswordChanged = true
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      }
    }
  }
</script>
